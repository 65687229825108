import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Breadcrumb, Row, Col, Figure, Card } from 'react-bootstrap'
import { Section } from '../components/Section'
import AfterBg from '../images/frame.png'
import PatternImg from "../images/line-pattern.png"
import About2Img from '../images/about2.png'
import About1Img from '../images/about1.png'
import CallToAction from '../components/CallToAction'
import ContractIcn from '../images/contract.svg'
import DesignIcn from '../images/design.svg'
import FinacncingIcn from '../images/financing.svg'
import { Link } from 'gatsby'

import InstallationIcn from '../images/installation-small.svg'
import { FairPolicyIcon, CustomerIcon, QualityIcon, WarantyIcon, LocationIcon, DiscountIcon, ArrowRight } from '../components/Icons'


const AboutUs = () => (
  <Layout>
    <SEO title="About Us" />
    <section style={{background:`url(${PatternImg})`}}>
      <Container className='position-relative' style={{zIndex:'1'}}>
        <Breadcrumb className='beadcrumbs-outer'>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>About</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <Section className="section about-welcome" pt='100px' pb='70px' bgBefore={`url(${PatternImg})`} bgAfter={`url(${AfterBg})`}>
      <Container>
        <Row>
          <Col lg={6} data-aos='fade-up'>
            <div className='section-heading'>
              <span className='section-subtitle'>Learn More About Who We Are</span>
              <h2 className='section-title'>Get to Know Carports R Us</h2>
            </div>
          </Col>
        </Row>
        <div className='section-content-outer'>
          <Row className='gutter-50' data-aos='fade-up'>
            <Col lg={5}>
              <Figure>
                <img src={About2Img} alt='about' className='img-fluid' />
              </Figure>
            </Col>
            <Col lg={{ span: 6, offset: 0 }}>
              <div className='section-heading'>
                <h2 className='section-title title-underline'>ABOUT US</h2>
                <span className='section-subtitle'>Carports R Us – The Best Regional Dealer of Metal Buildings in the Eastern US!</span>
              </div>
              <div className='section-content'>
                <p>We’ve been operating in central NC for years, and we’ve grown to be able to serve not just the entire state of North Carolina, but four other neighboring states as well, including South Carolina, Virginia, Georgia, and Tennessee. At Carports R Us, what we’re about is really pretty simple. We’re here to help you get the metal building you need! We want you to be thrilled with your experience with us, and we’ll work to help you get the metal structure you want, at the right price, delivered and installed as fast as possible! </p>
                <p>We understand that when it comes to a metal building investment, price matters to you. We believe in providing you with a metal building that will meet both your needs and your budget. You, our customers, are the reason why we’re here! We exist to serve your needs. To that end, we only use the best-quality steel materials to produce buildings in both 12-gauge and 14-gauge options. We also offer a 20-year warranty for framing and roofing, along with a 30-day workmanship warranty. And on top of all that, since we manufacture ourselves, we can usually get your building out to you faster than our competition, too!</p>
              </div>
            </Col>
          </Row>
          <Row className='gutter-25' data-aos='fade-up'>

            <Col lg={{ span: 5, offset: 1 }}>
              <div className='section-heading'>
                <h2 className='section-title title-underline'>OUR VISION AT Carports R Us </h2>
                <span className='section-subtitle'>It’s important for every company to have a North Star which guides it. Here’s ours!</span>
              </div>
              <div className='section-content'>
                <p>Our vision is to build our business with customers just like you. We want to ensure that you’re thrilled with our product, and we’ll work to manufacture, deliver, and install the exact building you want, as fast as possible!</p>
              </div>
            </Col>
            <Col lg={5}>
              <Figure>
                <img src={About1Img} alt='about' className='img-fluid' />
              </Figure>
            </Col>
          </Row>
        </div>
      </Container>
    </Section>
    <Section className='section by-metal-garage line-pattern' pt='90px' pb='90px' bgBefore={`url(${PatternImg})`} data-aos='fade-up'>
      <Container>
        <Row>
          <Col lg={10}>
            <div className='section-heading'>
              <span className='section-subheading'>Why Choose</span>
              <h2 className='section-title'>Carports R Us</h2>
              <p>At Carports R Us, our vision is really pretty simple. We’re here to build our business with customers just like you. We work to ensure that you’re thrilled with our product, and we’re committed to help you receive the metal structure you want with the quickest delivery and installation as possible!</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <Card className='shadow-card'>
              <div className='card-inner'>
                <FairPolicyIcon className='card-img-top' width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                <Card.Body>
                  <Card.Title className='title-underline'>Fair Price Policy</Card.Title>
                  <Card.Text>We’re here to get you a metal building to meet your needs and your budget.</Card.Text>
                </Card.Body>
              </div>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className='shadow-card' data-aos='fade-up'>
              <div className='card-inner'>
                <QualityIcon className='card-img-top' width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                <Card.Body>
                  <Card.Title className='title-underline'>Quality Materials</Card.Title>
                  <Card.Text>We only use the best materials to produce metal buildings in 12-gauge and 14-gauge options.</Card.Text>
                </Card.Body>
              </div>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className='shadow-card' data-aos='fade-up'>
              <div className='card-inner'>
                <CustomerIcon className='card-img-top' width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                <Card.Body>
                  <Card.Title className='title-underline'>Customer Centric</Card.Title>
                  <Card.Text>You, our customers, are the reason why we’re here! We exist to serve your needs.</Card.Text>
                </Card.Body>
              </div>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className='shadow-card' data-aos='fade-up'>
              <div className='card-inner'>
                <WarantyIcon className='card-img-top' width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                <Card.Body>
                  <Card.Title className='title-underline'>20-Year Warranty</Card.Title>
                  <Card.Text>We provide a 20-year warranty for the frame and roof, and a 30-day workmanship warranty.</Card.Text>
                </Card.Body>
              </div>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className='shadow-card' data-aos='fade-up'>
              <div className='card-inner'>
                <LocationIcon className='card-img-top' width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                <Card.Body>
                  <Card.Title className='title-underline'>Quick Installation</Card.Title>
                  <Card.Text>We get you your building fast! We can usually install within 3-4 weeks.</Card.Text>
                </Card.Body>
              </div>
            </Card>
          </Col>
          <Col lg={4} md={6}>
            <Card className='shadow-card' data-aos='fade-up'>
              <div className='card-inner'>
                <DiscountIcon className='card-img-top' width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                <Card.Body>
                  <Card.Title className='title-underline'>Serving NC & Beyond</Card.Title>
                  <Card.Text>Of course we serve North Carolina, but we also serve 4 other surrounding states as well.</Card.Text>
                </Card.Body>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Section>
    <CallToAction />
    <Section className='section by-metal-garage line-pattern' pt='90px' pb='90px' bgBefore={`url(${PatternImg})`}>
      <Container>
        <Row data-aos='fade-up'>
          <Col lg={7}>
            <div className='section-heading'>
              <span className='section-subheading'>Learn more about what we have to offer.</span>
              <h2 className='section-title'>OUR SPECIALTIES AT Carports R Us INCLUDE</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <div className='shadow-card card-flex col-xl-3 col-md-6 card' data-aos='fade-up'>
            <div className='card-inner'>
              <Card.Body>
                <div className='card-body-inner'>
                  <img src={DesignIcn} alt='Design & Construction' />
                  <Card.Title>Custom Design & Construction</Card.Title>
                </div>
                <Card.Text>Use our 3D Building Design Tool (with real-time pricing!) to create your own building plan.</Card.Text>
                <a href="https://carportrus.sensei3d.com/" target="_blank" rel="noopener noreferrer" className='btn btn-link text-secondary icon-right pl-0 text-uppercase'>Design Now <ArrowRight stroke='#cb0000' width="14px" height="14px" /></a>
              </Card.Body>
           </div>
          </div>
            <div className='shadow-card card-flex col-xl-3 col-md-6 card' data-aos='fade-up'>
              <div className='card-inner'>
                <Card.Body>
                  <div className='card-body-inner'>
                    <img src={InstallationIcn} alt='Experienced & Dedicated Team' />
                    <Card.Title>Experienced, Dedicated Team</Card.Title>
                  </div>
                  <Card.Text>We’ve been providing custom steel structures for years, and we’re equipped to serve you, too!</Card.Text>
                  <Link to="/contact" className='btn btn-link text-secondary icon-right pl-0 text-uppercase'>Contact Us <ArrowRight stroke='#cb0000' width="14px" height="14px" /></Link>
                </Card.Body>
              </div>
            </div>
            <div className='shadow-card card-flex col-xl-3 col-md-6 card' data-aos='fade-up'>
              <div className='card-inner'>
                <Card.Body>
                  <div className='card-body-inner'>
                    <img src={ContractIcn} alt='Rent-To-Own Program' />
                    <Card.Title>A Wide Variety of Metal Building Options</Card.Title>
                  </div>
                  <Card.Text>We do carports, garages, barns, storage buildings, workshops, clear span facilities, insulated buildings, and more!</Card.Text>
                  <Link to="/metal-buildings" className='btn btn-link text-secondary icon-right pl-0 text-uppercase'>Know More<ArrowRight stroke='#cb0000' width="14px" height="14px" /></Link>
                </Card.Body>
              </div>
            </div>
            <div className='shadow-card card-flex col-xl-3 col-md-6 card' data-aos='fade-up'>
              <div className='card-inner'>
                <Card.Body>
                  <div className='card-body-inner'>
                    <img src={FinacncingIcn} alt='Easy Financing Available' />
                    <Card.Title>Excellent Financing Options</Card.Title>
                  </div>
                  <Card.Text>We offer ways to help you afford the building you need, including financing and rent-to-own. </Card.Text>
                  <Link to="/financing" className='btn btn-link text-secondary icon-right pl-0 text-uppercase'>Know More <ArrowRight stroke='#cb0000' width="14px" height="14px" /></Link>
                </Card.Body>
              </div>
            </div>
        </Row>
      </Container>
    </Section>
  </Layout>
    )
    
    export default AboutUs
