import React from 'react';
import { Section } from '../Section'
import CtoBg from '../../images/cto-bg.png'
import { Container, Row, Col} from 'react-bootstrap'
import { CallIcon } from '../Icons'

const CallToAction = (props) => { 
  return (
    <Section className='section cto' pt='100px' pb='100px' bg={`url(${CtoBg})`}>
      <Container>
        <Row className='justify-content-between align-items-center' data-aos='fade-up'>
          <Col lg={8} xl={8}>
            <div className='section-heading'>
              <h2 className='section-title text-white'>FOR MORE INFOMATION CALL US NOW</h2>
            </div> 
          </Col>
          <Col lg={4} xl={4} className='text-md-right'>
          <a href='tel:8773304846' className='btn btn-secondary'><CallIcon stroke="#fff" width='34px' height='34px' />(877) 330-4846</a>
          </Col>
        </Row>
    </Container>
    </Section>
  )
}
export default CallToAction; 